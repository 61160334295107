<template>
  <div class="payment-record-detail">
    <div class="content">
      <div class="title">
        <van-icon name="location-o" />{{ getLocation(obj) }}
      </div>
      <van-cell-group class="cell-group">
        <van-cell title="房屋面积">
          <template #right-icon>
            <span class="address"
              >{{ Number(obj.roomAmountArea).toFixed(2) }}m²</span
            >
          </template>
        </van-cell>
        <van-cell title="单位金额">
          <template #right-icon>
            <span class="price-month"
              >{{ Number(obj.unitPrice).toFixed(2) }}元/㎡/月</span
            >
          </template>
        </van-cell>
        <van-cell title="账单日期">
          <template #right-icon>
            <span class="price-time"
              >{{ formatDate(obj.startTime) }}~{{
                formatDate(obj.endTime)
              }}</span
            >
          </template>
        </van-cell>
        <van-cell title="缴费金额">
          <template #right-icon>
            <span class="price">{{ Number(obj.totalCost).toFixed(2) }}元</span>
          </template>
        </van-cell>
        <van-cell title="缴费单号">
          <template #right-icon>
            <span class="odd">{{ obj.paymentNo || "无" }}</span>
          </template>
        </van-cell>
        <van-cell title="缴费时间">
          <template #right-icon>
            <span class="time">{{ obj.createTime }}</span>
          </template>
        </van-cell>
        <van-cell title="">
          <template #right-icon>
            <div class="wrap">
              <div>缴费发票</div>
              <img
                @click="showImg"
                v-if="obj.invoiceUrl"
                :src="obj.invoiceUrl"
              />
              <span class="txt">暂无发票!</span>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>

    <!-- 图片查看 -->
    <van-image-preview v-model="show" :images="images" @change="onChange">
      <!-- <template v-slot:index>第{{ index }}页</template> -->
    </van-image-preview>
  </div>
</template>

<script>
import { getPayDetail } from "@/api/propertyApi";
export default {
  name: "PaymentRecordsDetail",
  data() {
    return {
      show: false,
      index: 0,
      images: [
        "https://d1icd6shlvmxi6.cloudfront.net/gsc/X4NAYW/00/da/b1/00dab1b742d542af91dea6273da08f28/images/缴费记录详情/u49.png?pageId=40de078d-d243-491e-aa50-150372342087",
      ],
      obj: {},
    };
  },
  created() {
    this.getRecordsDetial();
  },
  computed: {
    // 获取住址
    getLocation() {
      return (obj) => {
        return `${obj.estateName}${obj.buildingName}${obj.unitName}${obj.roomName}`;
      };
    },
    // 格式化时间
    formatDate() {
      return (date) => {
        let arr = date.split("-");
        return `${arr[0]}年${arr[1]}月`;
      };
    },
  },
  methods: {
    // 获取详情
    async getRecordsDetial() {
      const { id } = this.$route.query;
      const res = await getPayDetail({
        id,
      });

      if (res.code == 200) {
        this.obj = res.data;
      }
    },
    onChange(index) {
      this.index = index;
    },
    // 查看图片
    showImg() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-record-detail {
  .content {
    padding: 10px;
    background-color: #fff;

    .title {
      font-size: 18px;
      padding-left: 10px;
    }

    .cell-group {
      margin-top: 10px;

      .address {
      }

      .price-month {
      }

      .price-time {
      }

      .price {
        color: #fb001e;
      }

      .wrap {
        width: 100%;
        // display: flex;
        img {
          width: 100%;
          margin-top: 10px;
        }

        .txt {
          display: block;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          color: #ddd;
        }
      }
    }
  }
}
</style>